.service-list {
  grid-gap: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px 20px;
  align-items: center;
  justify-content: center;
  max-width: 1450px;
  margin: 0;
}

.service-prices-info {
  display: flex;
  justify-content: space-evenly;
  padding: 15px;
  background-color: #d9d9d9;
  margin: 10px;
  color: rgb(167, 57, 17);
  border-radius: 5px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.08);
  min-width: 250px;
}
.prices-info {
  font-style: italic;
}
