h1 {
  margin: 0;
}
.home-page-container {
  width: 90%;
  margin: 0 auto;
}
.top-screen {
  background-color: #d1cfcf;
  padding: 60px;
  text-align: center;
}

.bottom-screen {
  padding: 20px 60px;
  text-align: center;
}
