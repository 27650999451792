.service-item-container {
  width: 360px;
  border-radius: 20px;
  background-color: hsl(0, 0%, 100%);
  border-radius: 20px;
  border: none;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.2);
  padding-bottom: 25px;
}

.service-item-heading {
  background-color: #d1cfcf;
  display: flex;
  justify-content: space-evenly;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.service-title {
  padding: 20px 0 20px 20px;
  margin: 0;
}

.service-item-prices {
  display: flex;
  justify-content: center;
}
.service-item-price {
  display: flex;
  justify-content: space-evenly;
  padding: 5px;
  background-color: #d9d9d9;
  margin: 20px;
  width: 300px;
  border-radius: 15px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.08);
}

.service-include {
  padding-left: 20px;
}

.service-entity {
  font-size: large;
}
