* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

@media (max-width: 1000px) {
  .toggle-btn {
    display: flex;
  }
  .hamburger {
    display: flex;
  }
  .contact-us-links {
    width: 100%;
    display: none;
  }
  .nav-container {
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
  }
  .contact-us-links ul {
    width: 100%;
    flex-direction: column;
  }
  .contact-us-links li {
    justify-content: center;
  }
  .contact-us-links.active {
    display: inline;
  }
  .top-screen {
    padding: 60px;
  }
}
