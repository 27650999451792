.nav-section {
  background-color: hsl(0, 0%, 85%, 0.3);
}
.home-page-link {
  display: flex;
  gap: 5px;
}

.nav-container {
  width: 75%;
  padding: 30px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.contact-us-links ul {
  display: flex;
  margin: 0;
  padding: 0;
}
.contact-us-links li {
  margin: 0;
  padding: 0;
}

.hamburger {
  position: absolute;
  right: 1rem;
  display: none;
}
